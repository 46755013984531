export function numberFormat(number, isForTooltip = false) {
  if (number === 'N/A') return number;
  if (number == null) return '';

  if (!isForTooltip) {
    if (number >= 1000000) {
      return `${(number / 1000000).toFixed(1)}M`;
    }
    if (number >= 1000) {
      return `${(number / 1000).toFixed(1)}K`;
    }
  }

  return new Intl.NumberFormat('en-En').format(number);
}
